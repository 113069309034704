// getHashedInvitationID returns just the hashed ID from the path:
// http://gatherings.warmspace.io/HASHED_ID
export const getHashedInvitationID = () => {
  const [, hashedInvitationID] = window.location.pathname.split("/");

  // Make sure the hashed ID is a valid format.
  // Four characters long, alphanumeric.
  if (!/^[a-zA-Z0-9]{4}$/.test(hashedInvitationID)) {
    return null;
  }

  return hashedInvitationID;
};
